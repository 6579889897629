var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "start", align: "center", dense: "" } },
    [
      _c("v-col", { attrs: { cols: "6" } }, [
        _c("h3", { staticClass: "title" }, [_vm._v("Relatórios Técnicos")])
      ]),
      _c(
        "v-col",
        { staticClass: "text-left", attrs: { cols: "6" } },
        [_c("TechnicalReportsInsert")],
        1
      ),
      _c("v-col", { attrs: { cols: "12" } }, [_c("hr")]),
      _c("v-col", { attrs: { cols: "12" } }, [_c("TechnicalReportsTable")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }