<template>
  <div>
    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          outlined
          x-small
          color="green"
          @click="dialog = true"
          ><v-icon small>add</v-icon></v-btn
        >
      </template>
      <span>Enviar um relatório técnico</span>
    </v-tooltip>

    <v-dialog v-model="dialog" max-width="700">
      <v-card outlined>
        <v-card-title>
          <div class="title">Submeter Relatório Técnico</div>
        </v-card-title>
        <v-card-text>
          <v-row justify="start" align="start" dense>
            <v-col cols="12" class="">
              <v-card outlined class="px-5 py-0 mb-5">
                <v-radio-group v-model="documentType" row mandatory disabled>
                  <template v-slot:label>
                    <div>Escolha o tipo de <strong>documento</strong>:</div>
                  </template>
                  <v-radio label="Relatório Técnico" value="0"></v-radio>
                  <v-radio label="PFG" value="1"></v-radio>
                  <v-radio label="TCC" value="2"></v-radio>
                </v-radio-group>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-text-field
                clearable
                outlined
                dense
                required
                label="Titulo"
                :rules="rules"
                v-model="form.title"
                placeholder="título do documento"
                :disabled="progress"
                counter="100"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                clearable
                outlined
                dense
                required
                :rules="rules"
                label="Autor(es)"
                hint="Lista de autores"
                v-model="form.author"
                placeholder="autores separados por vírgula."
                :disabled="progress"
                counter="255"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                clearable
                outlined
                dense
                :rules="rules"
                label="Institutição"
                v-model="form.institution"
                placeholder="ex.: Unicamp"
                :disabled="progress"
                counter="255"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                clearable
                dense
                label="Nota"
                v-model="form.note"
                outlined
                :rules="rules"
                placeholder="Ex.:In English, 12 pages.,Partly in English, partly in Portuguese, 60 pages."
                :disabled="progress"
                counter="255"
              ></v-text-field>
            </v-col>
             <v-col cols="12" sm="12">
               <v-text-field
                clearable
                dense
                label="Língua"
                v-model="form.language"
                outlined
                :rules="rules"
                placeholder="english, brazil"
                :disabled="progress"
                counter="50"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6" class="text-left">
              <v-file-input
                accept="application/pdf"
                label="relatório técnico"
                placeholder="Somente arquivos pdf"
                block
                outlined
                dense
                v-model="form.reportFile"
                append-icon="attachment"
                :prepend-icon="null"
                :rules="rules"
                :disabled="progress"
              ></v-file-input>
            </v-col>
            
            <v-col cols="12">
              <v-textarea
                outlined
                dense
                v-model="form.abstract_"
                label="Abstract"
                :rules="rules"
                placeholder="Abstract/Resumo"
                counter="2048"
                :disabled="progress"
              >
              </v-textarea>
            </v-col>
            <v-col cols="12" class="">
              <VueMathjax></VueMathjax>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-5">
          <v-row justify="center" align="center" dense>
            <v-col cols="12" class="text-center" v-show="progress">
              <v-progress-linear
                indeterminate
                color="green"
              ></v-progress-linear>
            </v-col>
            <v-col cols="12" sm="12" md="6" class="text-center">
              <v-btn color="red" outlined @click="close()" :disabled="progress"
                >Cancelar<v-icon>clear</v-icon></v-btn
              >
            </v-col>
            <v-col cols="12" sm="12" md="6" class="text-center">
              <v-btn
                color="green"
                dense
                outlined
                @click="postAsyncTechnicalReport()"
                :disabled="progress"
                >Submeter <v-icon>check</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="timeout" :color="color">
      <v-row justify="center" align="center" class="pa-3">
        {{ snackbarMessage }}
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import { VueMathjax } from "vue-mathjax";

export default {
  name: "TechnicalReportInsert",
  props: [],
  components: { VueMathjax },

  data() {
    return {
      color: null,
      snackbar: false,
      timeout: 5000,
      snackbarMessage: "",
      dialog: false,
      form: {},
      technicalReport: new FormData(),
      documentType: 0,
      progress: false,
      rules: [(t) => !!t || "O campo não pode ser vazio!!!"],
    };
  },

  methods: {
    ...mapActions("technicalReports", ["ActionPostTechnicalReport"]),

    async postAsyncTechnicalReport() {
      
      if (this.validatedFields()) {
        this.progress = true;

        this.technicalReport.append("reportFile", this.form.reportFile);
        this.technicalReport.append("language", this.form.language);
        this.technicalReport.append("title", this.form.title);
        this.technicalReport.append("note", this.form.note);
        this.technicalReport.append("author", this.form.author);
        this.technicalReport.append("abstract_", this.form.abstract_);
        this.technicalReport.append("institution", this.form.institution);

        try {
          await this.ActionPostTechnicalReport(this.technicalReport);
          this.color = null;
          this.snackbarMessage = "Relatório submetido com sucesso!";
          this.close();
         // this.$destroy();
         // this.$createElement();
        } catch (e) {
          this.color = 'red';
          this.snackbarMessage = "Erro ao submeter o relatório técnico! " + e.bodyText;
          console.error(e);
        } finally {
          this.snackbar = true;
          this.progress = false;
        }
      }
      else {
        this.snackbarMessage = "Existem campos vazios!!!!";
        this.color = 'red';
        this.snackbar = true;
      }
    },

    validatedFields() {
      if (
        this.form.reportFile != null &&
        this.form.title != null &&
        this.form.author != null &&
        this.form.abstract_ != null &&
        this.form.institution != null &&
        this.form.note != null &&
        this.form.language != null
      )
        return true;
      return false;
    },

    close() {
      this.dialog = false;
      this.technicalReport = new FormData();
      this.form = {};
    },
  },
};
</script>

<style>
</style>