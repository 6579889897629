<template>
  <v-row justify="start" align="center" dense>
    <v-col cols="6">
      <h3 class="title">Relatórios Técnicos</h3>
    </v-col>
    <v-col cols="6" class="text-left">
      <TechnicalReportsInsert></TechnicalReportsInsert>
    </v-col>
    <v-col cols="12">
    <hr />
    </v-col>

    <v-col cols="12">
      <TechnicalReportsTable></TechnicalReportsTable>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import TechnicalReportsTable from "./TechnicalReportsTable.vue";
import TechnicalReportsInsert from "./TechnicalReportInsert.vue";

export default {
  name: "TechnicalReports",
  components: { TechnicalReportsTable, TechnicalReportsInsert },
  data() {
    return {};
  },
  created() {},
  watch: {},

  computed: {
    ...mapGetters("technicalReports", ["technicalReports"]),
  },

  methods: {
    ...mapActions("technicalReports", ["ActionGetTechnicalReports"]),
  },
};
</script>