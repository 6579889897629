var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    attrs: {
      headers: _vm.headers,
      items: _vm.items,
      search: _vm.search,
      "item-key": "id",
      "items-per-page": _vm.technicalReports.length,
      "hide-default-footer": "",
      loading: _vm.loading,
      "loading-text": "Buscando relatórios técnicos",
      "no-data-text": "Não foram encontrados relatórios técnicos"
    },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function(props) {
          return [
            _c("tr", [
              _c("td", { staticClass: "text-xs-left" }, [
                _vm._v(_vm._s(_vm.dmyh(props.item.createdAt)))
              ]),
              _c("td", { staticClass: "text-xs-left" }, [
                _vm._v(_vm._s(props.item.title))
              ]),
              _c("td", { staticClass: "text-xs-left" }, [
                _vm._v(_vm._s(props.item.author))
              ]),
              _c("td", { staticClass: "text-xs-left" }, [
                _vm._v(_vm._s(props.item.status))
              ]),
              _c(
                "td",
                { staticClass: "text-xs-left" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { "x-small": "", outlined: "", color: "green" },
                      on: {
                        click: function($event) {
                          return _vm.openTechnicalReportDetails(props.item.id)
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [_vm._v("search")])
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }