<template>
  <v-data-table
    v-bind:headers="headers"
    v-bind:items="items"
    :search="search"
    item-key="id"
    :items-per-page="technicalReports.length"
    hide-default-footer
    :loading="loading"
    loading-text="Buscando relatórios técnicos"
    no-data-text="Não foram encontrados relatórios técnicos"
  >
    <template v-slot:item="props">
      <tr>
        <td class="text-xs-left">{{ dmyh(props.item.createdAt) }}</td>
        <td class="text-xs-left">{{ props.item.title }}</td>
        <td class="text-xs-left">{{ props.item.author }}</td>
        <td class="text-xs-left">{{ props.item.status }}</td>
        <td class="text-xs-left">
          <v-btn
            x-small
            outlined
            color="green"
            @click="openTechnicalReportDetails(props.item.id)"
          >
            <v-icon small>search</v-icon>
          </v-btn>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>
<script>
import { mapActions, mapState } from "vuex";

import { formatDate } from "@/utils/formatDate";
import * as storage from "@/pages/auth/storage";

export default {
  name: "TechnicalReportsTable",
  components: {},
  data() {
    return {
      search: "",
      loading: false,
      user: null,
      items: [],
      headers: [
        {
          sortable: true,
          text: "Criado em",
          value: "",
          align: "left",
        },
        {
          sortable: true,
          text: "Título",
          value: "",
          align: "left",
        },
        {
          sortable: true,
          text: "Autor",
          value: "",
          align: "left",
        },
        {
          sortable: true,
          text: "Situação",
          value: "",
          align: "left",
        },
        {
          sortable: false,
          text: "Consultar",
          value: "",
          align: "left",
        },
      ],
    };
  },
  created() {
    this.user = storage.getLocalData().user;
    this.getAsyncTechnicalReports(this.user.username);
  },

  computed: {
    ...mapState("technicalReports", ["technicalReports"]),
  },

  methods: {
    ...mapActions("technicalReports", [
      "ActionGetTechnicalReports",
      "ActionGetTechnicalReportStatusByTechnicalReportId",
    ]),

    async getAsyncTechnicalReports(username) {
      try {
        this.loading = true;
        await this.ActionGetTechnicalReports(username);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async getAsyncStatuses(id) {
      try {
        return await this.ActionGetTechnicalReportStatusByTechnicalReportId(id);
      } catch (err) {
        console.log(err);
        return [{ status: "Submetido" }];
      }
    },

    populateItems(reports) {
      reports.forEach(async (e) => {
        let statuses = await this.getAsyncStatuses(e.id);
        let status = this.ordenationStatuses(statuses)[0]? this.ordenationStatuses(statuses)[0].status: "";

        this.items.push({
          id: e.id,
          createdAt: e.createdAt,
          author: e.author,
          title: e.title,
          status: status,
          statuses: statuses,
        });
      });
    },

    ordenationStatuses(value) {
      if (value) {
        let v = JSON.parse(JSON.stringify(value));
        return v.sort(function (a, b) {
          if (a.id < b.id) {
            return 1;
          }
          if (a.id > b.id) {
            return -1;
          }
          return 0;
        });
      }
    },

    dmyh(date) {
      let d = new Date(date);
      return formatDate.dmyh(d);
    },

    openTechnicalReportDetails(id) {
      this.$router.push({
        name: "TechnicalReportDetails",
        params: { id: id },
      });
    },
  },

  watch: {
    technicalReports(newValue) {
      this.populateItems(newValue);
    },
  },
};
</script>